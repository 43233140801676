import { render, staticRenderFns } from "./CustomInformeUrbanistico.vue?vue&type=template&id=205255f1&scoped=true"
import script from "./CustomInformeUrbanistico.vue?vue&type=script&lang=js"
export * from "./CustomInformeUrbanistico.vue?vue&type=script&lang=js"
import style0 from "./CustomInformeUrbanistico.vue?vue&type=style&index=0&id=205255f1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205255f1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormsInvalidInformeDialog: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/forms/InvalidInformeDialog.vue').default})
