import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2fceb34a = () => interopDefault(import('../pages/autenticar-error.vue' /* webpackChunkName: "pages/autenticar-error" */))
const _4d510459 = () => interopDefault(import('../themes/RioNegro/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _5b084c7e = () => interopDefault(import('../pages/auth/index/ChangePasswordFuncionarios.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordFuncionarios" */))
const _6e2f43ec = () => interopDefault(import('../pages/auth/index/ChangePasswordInstitucionales.vue' /* webpackChunkName: "pages/auth/index/ChangePasswordInstitucionales" */))
const _0199e9e4 = () => interopDefault(import('../pages/auth/index/LinkWallet.vue' /* webpackChunkName: "pages/auth/index/LinkWallet" */))
const _c32231da = () => interopDefault(import('../themes/RioNegro/pages/auth/index/Login.vue' /* webpackChunkName: "pages/auth/index/Login" */))
const _6ff68c0c = () => interopDefault(import('../pages/auth/index/login-funcionarios/index.vue' /* webpackChunkName: "pages/auth/index/login-funcionarios/index" */))
const _7c492168 = () => interopDefault(import('../pages/auth/index/login-institucional/index.vue' /* webpackChunkName: "pages/auth/index/login-institucional/index" */))
const _70921d57 = () => interopDefault(import('../pages/auth/index/LoginWallet.vue' /* webpackChunkName: "pages/auth/index/LoginWallet" */))
const _3c036a05 = () => interopDefault(import('../pages/auth/index/Logout.vue' /* webpackChunkName: "pages/auth/index/Logout" */))
const _6ba5162e = () => interopDefault(import('../themes/RioNegro/pages/auth/index/Register.vue' /* webpackChunkName: "pages/auth/index/Register" */))
const _2cf42e5f = () => interopDefault(import('../pages/auth/index/ResetPassword.vue' /* webpackChunkName: "pages/auth/index/ResetPassword" */))
const _0fe6216a = () => interopDefault(import('../pages/digital-citizen.vue' /* webpackChunkName: "pages/digital-citizen" */))
const _ea55753c = () => interopDefault(import('../pages/digital-citizen-levels.vue' /* webpackChunkName: "pages/digital-citizen-levels" */))
const _59de9738 = () => interopDefault(import('../themes/RioNegro/pages/digital-services/index.vue' /* webpackChunkName: "pages/digital-services/index" */))
const _a3a4ed46 = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _104aa6e0 = () => interopDefault(import('../pages/expediente.vue' /* webpackChunkName: "pages/expediente" */))
const _7c0cb9f7 = () => interopDefault(import('../pages/expediente/apoderados.vue' /* webpackChunkName: "pages/expediente/apoderados" */))
const _0bcda876 = () => interopDefault(import('../pages/expediente/appointments/index.vue' /* webpackChunkName: "pages/expediente/appointments/index" */))
const _0cf36d6c = () => interopDefault(import('../pages/expediente/companies.vue' /* webpackChunkName: "pages/expediente/companies" */))
const _ad991b02 = () => interopDefault(import('../pages/expediente/identidad.vue' /* webpackChunkName: "pages/expediente/identidad" */))
const _3db6ab53 = () => interopDefault(import('../pages/expediente/inspections/index.vue' /* webpackChunkName: "pages/expediente/inspections/index" */))
const _7013872f = () => interopDefault(import('../pages/expediente/payments/index.vue' /* webpackChunkName: "pages/expediente/payments/index" */))
const _58ceb710 = () => interopDefault(import('../pages/expediente/portadocumentos.vue' /* webpackChunkName: "pages/expediente/portadocumentos" */))
const _595000b2 = () => interopDefault(import('../pages/expediente/appointments/_appointmentId.vue' /* webpackChunkName: "pages/expediente/appointments/_appointmentId" */))
const _08ddbf0f = () => interopDefault(import('../pages/expediente/inspections/_inspectionId.vue' /* webpackChunkName: "pages/expediente/inspections/_inspectionId" */))
const _469b0c04 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/index.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/index" */))
const _1ebc5309 = () => interopDefault(import('../pages/expediente/module/_module_id/_submodule_id/_details_id.vue' /* webpackChunkName: "pages/expediente/module/_module_id/_submodule_id/_details_id" */))
const _9e3e7666 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _4b674e20 = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _91170aea = () => interopDefault(import('../pages/forward-email.vue' /* webpackChunkName: "pages/forward-email" */))
const _022a4313 = () => interopDefault(import('../pages/mercadopago.vue' /* webpackChunkName: "pages/mercadopago" */))
const _99ad4f46 = () => interopDefault(import('../pages/motordepagos.vue' /* webpackChunkName: "pages/motordepagos" */))
const _4e98d29e = () => interopDefault(import('../pages/notice-of-privacy.vue' /* webpackChunkName: "pages/notice-of-privacy" */))
const _c7a8025e = () => interopDefault(import('../pages/persona-moral.vue' /* webpackChunkName: "pages/persona-moral" */))
const _44e8c04a = () => interopDefault(import('../pages/predial.vue' /* webpackChunkName: "pages/predial" */))
const _0c58681c = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _59214b57 = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _9f8bac66 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _80b3cdc4 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _76890913 = () => interopDefault(import('../pages/user-guide.vue' /* webpackChunkName: "pages/user-guide" */))
const _f0958f54 = () => interopDefault(import('../pages/user-guide-functionary.vue' /* webpackChunkName: "pages/user-guide-functionary" */))
const _13453093 = () => interopDefault(import('../pages/validacion-pago.vue' /* webpackChunkName: "pages/validacion-pago" */))
const _4afbd74c = () => interopDefault(import('../pages/verificador/index.vue' /* webpackChunkName: "pages/verificador/index" */))
const _bd405eec = () => interopDefault(import('../pages/what-is.vue' /* webpackChunkName: "pages/what-is" */))
const _2d77d096 = () => interopDefault(import('../pages/admin/catalogs/index.vue' /* webpackChunkName: "pages/admin/catalogs/index" */))
const _609aa94e = () => interopDefault(import('../pages/admin/changelog/index.vue' /* webpackChunkName: "pages/admin/changelog/index" */))
const _708f39ba = () => interopDefault(import('../pages/admin/citas/index.vue' /* webpackChunkName: "pages/admin/citas/index" */))
const _5af4a24e = () => interopDefault(import('../pages/admin/dashboard/index.vue' /* webpackChunkName: "pages/admin/dashboard/index" */))
const _38ea3a96 = () => interopDefault(import('../pages/admin/filters/index.vue' /* webpackChunkName: "pages/admin/filters/index" */))
const _218a009f = () => interopDefault(import('../pages/admin/inspectores/index.vue' /* webpackChunkName: "pages/admin/inspectores/index" */))
const _d3a5317e = () => interopDefault(import('../pages/admin/modules/index.vue' /* webpackChunkName: "pages/admin/modules/index" */))
const _0f747889 = () => interopDefault(import('../pages/admin/payments/index.vue' /* webpackChunkName: "pages/admin/payments/index" */))
const _36d0c920 = () => interopDefault(import('../pages/admin/preventiveDelivery/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/index" */))
const _132006e3 = () => interopDefault(import('../pages/admin/profile/index.vue' /* webpackChunkName: "pages/admin/profile/index" */))
const _5f2c2c02 = () => interopDefault(import('../pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _c788d1be = () => interopDefault(import('../pages/auth/CompleteData.vue' /* webpackChunkName: "pages/auth/CompleteData" */))
const _7cf2bb5f = () => interopDefault(import('../pages/citizen/certificates/index.vue' /* webpackChunkName: "pages/citizen/certificates/index" */))
const _02cf6fb0 = () => interopDefault(import('../pages/tools/ide/index.vue' /* webpackChunkName: "pages/tools/ide/index" */))
const _1082d564 = () => interopDefault(import('../pages/admin/catalogs/new.vue' /* webpackChunkName: "pages/admin/catalogs/new" */))
const _c443a83e = () => interopDefault(import('../pages/admin/citas/configuration/index.vue' /* webpackChunkName: "pages/admin/citas/configuration/index" */))
const _4adf6535 = () => interopDefault(import('../pages/admin/forms/builder/index.vue' /* webpackChunkName: "pages/admin/forms/builder/index" */))
const _26cc7e06 = () => interopDefault(import('../pages/admin/inspectores/configuration/index.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/index" */))
const _e942bdb0 = () => interopDefault(import('../pages/admin/modules/configuration/index.vue' /* webpackChunkName: "pages/admin/modules/configuration/index" */))
const _d0ec1406 = () => interopDefault(import('../pages/admin/operators/inbox.vue' /* webpackChunkName: "pages/admin/operators/inbox" */))
const _d7ac5720 = () => interopDefault(import('../pages/admin/payments/configuration/index.vue' /* webpackChunkName: "pages/admin/payments/configuration/index" */))
const _69265717 = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/index.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/index" */))
const _9d55df2e = () => interopDefault(import('../pages/admin/users/configuration/index.vue' /* webpackChunkName: "pages/admin/users/configuration/index" */))
const _7fe516be = () => interopDefault(import('../pages/citizen/certificates/verification/index.vue' /* webpackChunkName: "pages/citizen/certificates/verification/index" */))
const _587d42cd = () => interopDefault(import('../pages/admin/inspectores/configuration/catalog.vue' /* webpackChunkName: "pages/admin/inspectores/configuration/catalog" */))
const _6bc51b9e = () => interopDefault(import('../pages/admin/preventiveDelivery/configuration/catalog.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/configuration/catalog" */))
const _c1943894 = () => interopDefault(import('../pages/citizen/certificates/form-certs/back_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/back_cert" */))
const _0cd9d4ce = () => interopDefault(import('../pages/admin/citas/configuration/office/new.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/new" */))
const _7738cb14 = () => interopDefault(import('../themes/RioNegro/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0d6d5204 = () => interopDefault(import('../pages/admin/citas/configuration/office/_officeId.vue' /* webpackChunkName: "pages/admin/citas/configuration/office/_officeId" */))
const _f8290214 = () => interopDefault(import('../pages/admin/catalogs/terms/_id.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_id" */))
const _f53e019a = () => interopDefault(import('../pages/admin/citas/details/_detailsId.vue' /* webpackChunkName: "pages/admin/citas/details/_detailsId" */))
const _e68840b8 = () => interopDefault(import('../pages/admin/forms/builder/_builderid.vue' /* webpackChunkName: "pages/admin/forms/builder/_builderid" */))
const _14e68384 = () => interopDefault(import('../pages/admin/forms/details/_detailsid.vue' /* webpackChunkName: "pages/admin/forms/details/_detailsid" */))
const _dde7585a = () => interopDefault(import('../pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId.vue' /* webpackChunkName: "pages/admin/inspectores/inspectionDetailsId/_inspectionDetailsId" */))
const _020b1238 = () => interopDefault(import('../pages/admin/operators/details/_detailsid.vue' /* webpackChunkName: "pages/admin/operators/details/_detailsid" */))
const _882dd080 = () => interopDefault(import('../pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId.vue' /* webpackChunkName: "pages/admin/preventiveDelivery/preventiveDeliveryId/_preventiveDeliveryId" */))
const _3574058a = () => interopDefault(import('../pages/citizen/certificates/digital-identity/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/digital-identity/_cert" */))
const _14f3f5bd = () => interopDefault(import('../pages/citizen/certificates/form-certs/_cert.vue' /* webpackChunkName: "pages/citizen/certificates/form-certs/_cert" */))
const _1eeb12e9 = () => interopDefault(import('../pages/admin/forms/answers/_form/_answerId.vue' /* webpackChunkName: "pages/admin/forms/answers/_form/_answerId" */))
const _5ec467ca = () => interopDefault(import('../pages/admin/catalogs/terms/_.vue' /* webpackChunkName: "pages/admin/catalogs/terms/_" */))
const _0665ff04 = () => interopDefault(import('../pages/admin/catalogs/_id.vue' /* webpackChunkName: "pages/admin/catalogs/_id" */))
const _10df2dd6 = () => interopDefault(import('../pages/admin/forms/_formid.vue' /* webpackChunkName: "pages/admin/forms/_formid" */))
const _a4901c90 = () => interopDefault(import('../pages/admin/modules/_moduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/index" */))
const _b4a95a42 = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/index" */))
const _32f0950e = () => interopDefault(import('../pages/admin/modules/_moduleId/playlist/_playlistId.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/playlist/_playlistId" */))
const _240835b5 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/index" */))
const _da696da8 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/inbox/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/inbox/index" */))
const _807ab910 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/uploads/index.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/uploads/index" */))
const _54a7a6f8 = () => interopDefault(import('../pages/admin/modules/_moduleId/_submoduleId/details/_detailsid.vue' /* webpackChunkName: "pages/admin/modules/_moduleId/_submoduleId/details/_detailsid" */))
const _de17db80 = () => interopDefault(import('../pages/certificates/_id.vue' /* webpackChunkName: "pages/certificates/_id" */))
const _a0ef55ae = () => interopDefault(import('../pages/finalDocument/_finalDocument.vue' /* webpackChunkName: "pages/finalDocument/_finalDocument" */))
const _7c6c051b = () => interopDefault(import('../pages/payments/_paymentId.vue' /* webpackChunkName: "pages/payments/_paymentId" */))
const _431b74d6 = () => interopDefault(import('../pages/playlist/_id.vue' /* webpackChunkName: "pages/playlist/_id" */))
const _3dddd0aa = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/index" */))
const _88248a5c = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId" */))
const _15e35bd5 = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/index.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/index" */))
const _26337466 = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/appointment.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/appointment" */))
const _f25e10ac = () => interopDefault(import('../pages/modules/_module/_submodule/_postId/description.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/description" */))
const _5de95052 = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/request.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/request" */))
const _d18858ac = () => interopDefault(import('../themes/RioNegro/pages/modules/_module/_submodule/_postId/steps.vue' /* webpackChunkName: "pages/modules/_module/_submodule/_postId/steps" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autenticar-error",
    component: _2fceb34a,
    name: "autenticar-error"
  }, {
    path: "/auth",
    component: _4d510459,
    name: "auth",
    children: [{
      path: "ChangePasswordFuncionarios",
      component: _5b084c7e,
      name: "auth-index-ChangePasswordFuncionarios"
    }, {
      path: "ChangePasswordInstitucionales",
      component: _6e2f43ec,
      name: "auth-index-ChangePasswordInstitucionales"
    }, {
      path: "LinkWallet",
      component: _0199e9e4,
      name: "auth-index-LinkWallet"
    }, {
      path: "Login",
      component: _c32231da,
      name: "auth-index-Login"
    }, {
      path: "login-funcionarios",
      component: _6ff68c0c,
      name: "auth-index-login-funcionarios"
    }, {
      path: "login-institucional",
      component: _7c492168,
      name: "auth-index-login-institucional"
    }, {
      path: "LoginWallet",
      component: _70921d57,
      name: "auth-index-LoginWallet"
    }, {
      path: "Logout",
      component: _3c036a05,
      name: "auth-index-Logout"
    }, {
      path: "Register",
      component: _6ba5162e,
      name: "auth-index-Register"
    }, {
      path: "ResetPassword",
      component: _2cf42e5f,
      name: "auth-index-ResetPassword"
    }]
  }, {
    path: "/digital-citizen",
    component: _0fe6216a,
    name: "digital-citizen"
  }, {
    path: "/digital-citizen-levels",
    component: _ea55753c,
    name: "digital-citizen-levels"
  }, {
    path: "/digital-services",
    component: _59de9738,
    name: "digital-services"
  }, {
    path: "/email-action-handler",
    component: _a3a4ed46,
    name: "email-action-handler"
  }, {
    path: "/expediente",
    component: _104aa6e0,
    name: "expediente",
    children: [{
      path: "apoderados",
      component: _7c0cb9f7,
      name: "expediente-apoderados"
    }, {
      path: "appointments",
      component: _0bcda876,
      name: "expediente-appointments"
    }, {
      path: "companies",
      component: _0cf36d6c,
      name: "expediente-companies"
    }, {
      path: "identidad",
      component: _ad991b02,
      name: "expediente-identidad"
    }, {
      path: "inspections",
      component: _3db6ab53,
      name: "expediente-inspections"
    }, {
      path: "payments",
      component: _7013872f,
      name: "expediente-payments"
    }, {
      path: "portadocumentos",
      component: _58ceb710,
      name: "expediente-portadocumentos"
    }, {
      path: "appointments/:appointmentId",
      component: _595000b2,
      name: "expediente-appointments-appointmentId"
    }, {
      path: "inspections/:inspectionId",
      component: _08ddbf0f,
      name: "expediente-inspections-inspectionId"
    }, {
      path: "module/:module_id?/:submodule_id",
      component: _469b0c04,
      name: "expediente-module-module_id-submodule_id"
    }, {
      path: "module/:module_id?/:submodule_id?/:details_id",
      component: _1ebc5309,
      name: "expediente-module-module_id-submodule_id-details_id"
    }]
  }, {
    path: "/faq",
    component: _9e3e7666,
    name: "faq"
  }, {
    path: "/forbidden",
    component: _4b674e20,
    name: "forbidden"
  }, {
    path: "/forward-email",
    component: _91170aea,
    name: "forward-email"
  }, {
    path: "/mercadopago",
    component: _022a4313,
    name: "mercadopago"
  }, {
    path: "/motordepagos",
    component: _99ad4f46,
    name: "motordepagos"
  }, {
    path: "/notice-of-privacy",
    component: _4e98d29e,
    name: "notice-of-privacy"
  }, {
    path: "/persona-moral",
    component: _c7a8025e,
    name: "persona-moral"
  }, {
    path: "/predial",
    component: _44e8c04a,
    name: "predial"
  }, {
    path: "/reset-password",
    component: _0c58681c,
    name: "reset-password"
  }, {
    path: "/server-error",
    component: _59214b57,
    name: "server-error"
  }, {
    path: "/terms-and-conditions",
    component: _9f8bac66,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-service",
    component: _80b3cdc4,
    name: "terms-of-service"
  }, {
    path: "/user-guide",
    component: _76890913,
    name: "user-guide"
  }, {
    path: "/user-guide-functionary",
    component: _f0958f54,
    name: "user-guide-functionary"
  }, {
    path: "/validacion-pago",
    component: _13453093,
    name: "validacion-pago"
  }, {
    path: "/verificador",
    component: _4afbd74c,
    name: "verificador"
  }, {
    path: "/what-is",
    component: _bd405eec,
    name: "what-is"
  }, {
    path: "/admin/catalogs",
    component: _2d77d096,
    name: "admin-catalogs"
  }, {
    path: "/admin/changelog",
    component: _609aa94e,
    name: "admin-changelog"
  }, {
    path: "/admin/citas",
    component: _708f39ba,
    name: "admin-citas"
  }, {
    path: "/admin/dashboard",
    component: _5af4a24e,
    name: "admin-dashboard"
  }, {
    path: "/admin/filters",
    component: _38ea3a96,
    name: "admin-filters"
  }, {
    path: "/admin/inspectores",
    component: _218a009f,
    name: "admin-inspectores"
  }, {
    path: "/admin/modules",
    component: _d3a5317e,
    name: "admin-modules"
  }, {
    path: "/admin/payments",
    component: _0f747889,
    name: "admin-payments"
  }, {
    path: "/admin/preventiveDelivery",
    component: _36d0c920,
    name: "admin-preventiveDelivery"
  }, {
    path: "/admin/profile",
    component: _132006e3,
    name: "admin-profile"
  }, {
    path: "/admin/settings",
    component: _5f2c2c02,
    name: "admin-settings"
  }, {
    path: "/auth/CompleteData",
    component: _c788d1be,
    name: "auth-CompleteData"
  }, {
    path: "/citizen/certificates",
    component: _7cf2bb5f,
    name: "citizen-certificates"
  }, {
    path: "/tools/ide",
    component: _02cf6fb0,
    name: "tools-ide"
  }, {
    path: "/admin/catalogs/new",
    component: _1082d564,
    name: "admin-catalogs-new"
  }, {
    path: "/admin/citas/configuration",
    component: _c443a83e,
    name: "admin-citas-configuration"
  }, {
    path: "/admin/forms/builder",
    component: _4adf6535,
    name: "admin-forms-builder"
  }, {
    path: "/admin/inspectores/configuration",
    component: _26cc7e06,
    name: "admin-inspectores-configuration"
  }, {
    path: "/admin/modules/configuration",
    component: _e942bdb0,
    name: "admin-modules-configuration"
  }, {
    path: "/admin/operators/inbox",
    component: _d0ec1406,
    name: "admin-operators-inbox"
  }, {
    path: "/admin/payments/configuration",
    component: _d7ac5720,
    name: "admin-payments-configuration"
  }, {
    path: "/admin/preventiveDelivery/configuration",
    component: _69265717,
    name: "admin-preventiveDelivery-configuration"
  }, {
    path: "/admin/users/configuration",
    component: _9d55df2e,
    name: "admin-users-configuration"
  }, {
    path: "/citizen/certificates/verification",
    component: _7fe516be,
    name: "citizen-certificates-verification"
  }, {
    path: "/admin/inspectores/configuration/catalog",
    component: _587d42cd,
    name: "admin-inspectores-configuration-catalog"
  }, {
    path: "/admin/preventiveDelivery/configuration/catalog",
    component: _6bc51b9e,
    name: "admin-preventiveDelivery-configuration-catalog"
  }, {
    path: "/citizen/certificates/form-certs/back_cert",
    component: _c1943894,
    name: "citizen-certificates-form-certs-back_cert"
  }, {
    path: "/admin/citas/configuration/office/new",
    component: _0cd9d4ce,
    name: "admin-citas-configuration-office-new"
  }, {
    path: "/",
    component: _7738cb14,
    name: "index"
  }, {
    path: "/admin/citas/configuration/office/:officeId?",
    component: _0d6d5204,
    name: "admin-citas-configuration-office-officeId"
  }, {
    path: "/admin/catalogs/terms/:id?",
    component: _f8290214,
    name: "admin-catalogs-terms-id"
  }, {
    path: "/admin/citas/details/:detailsId?",
    component: _f53e019a,
    name: "admin-citas-details-detailsId"
  }, {
    path: "/admin/forms/builder/:builderid",
    component: _e68840b8,
    name: "admin-forms-builder-builderid"
  }, {
    path: "/admin/forms/details/:detailsid?",
    component: _14e68384,
    name: "admin-forms-details-detailsid"
  }, {
    path: "/admin/inspectores/inspectionDetailsId/:inspectionDetailsId?",
    component: _dde7585a,
    name: "admin-inspectores-inspectionDetailsId-inspectionDetailsId"
  }, {
    path: "/admin/operators/details/:detailsid?",
    component: _020b1238,
    name: "admin-operators-details-detailsid"
  }, {
    path: "/admin/preventiveDelivery/preventiveDeliveryId/:preventiveDeliveryId?",
    component: _882dd080,
    name: "admin-preventiveDelivery-preventiveDeliveryId-preventiveDeliveryId"
  }, {
    path: "/citizen/certificates/digital-identity/:cert?",
    component: _3574058a,
    name: "citizen-certificates-digital-identity-cert"
  }, {
    path: "/citizen/certificates/form-certs/:cert?",
    component: _14f3f5bd,
    name: "citizen-certificates-form-certs-cert"
  }, {
    path: "/admin/forms/answers/:form?/:answerId?",
    component: _1eeb12e9,
    name: "admin-forms-answers-form-answerId"
  }, {
    path: "/admin/catalogs/terms/*",
    component: _5ec467ca,
    name: "admin-catalogs-terms-all"
  }, {
    path: "/admin/catalogs/:id",
    component: _0665ff04,
    name: "admin-catalogs-id"
  }, {
    path: "/admin/forms/:formid?",
    component: _10df2dd6,
    name: "admin-forms-formid"
  }, {
    path: "/admin/modules/:moduleId",
    component: _a4901c90,
    name: "admin-modules-moduleId"
  }, {
    path: "/admin/modules/:moduleId/playlist",
    component: _b4a95a42,
    name: "admin-modules-moduleId-playlist"
  }, {
    path: "/admin/modules/:moduleId/playlist/:playlistId",
    component: _32f0950e,
    name: "admin-modules-moduleId-playlist-playlistId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId",
    component: _240835b5,
    name: "admin-modules-moduleId-submoduleId"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/inbox",
    component: _da696da8,
    name: "admin-modules-moduleId-submoduleId-inbox"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/uploads",
    component: _807ab910,
    name: "admin-modules-moduleId-submoduleId-uploads"
  }, {
    path: "/admin/modules/:moduleId/:submoduleId/details/:detailsid?",
    component: _54a7a6f8,
    name: "admin-modules-moduleId-submoduleId-details-detailsid"
  }, {
    path: "/certificates/:id?",
    component: _de17db80,
    name: "certificates-id"
  }, {
    path: "/finalDocument/:finalDocument?",
    component: _a0ef55ae,
    name: "finalDocument-finalDocument"
  }, {
    path: "/payments/:paymentId?",
    component: _7c6c051b,
    name: "payments-paymentId"
  }, {
    path: "/playlist/:id?",
    component: _431b74d6,
    name: "playlist-id"
  }, {
    path: "/modules/:module?/:submodule",
    component: _3dddd0aa,
    name: "modules-module-submodule"
  }, {
    path: "/modules/:module?/:submodule?/:postId",
    component: _88248a5c,
    children: [{
      path: "",
      component: _15e35bd5,
      name: "modules-module-submodule-postId"
    }, {
      path: "appointment",
      component: _26337466,
      name: "modules-module-submodule-postId-appointment"
    }, {
      path: "description",
      component: _f25e10ac,
      name: "modules-module-submodule-postId-description"
    }, {
      path: "request",
      component: _5de95052,
      name: "modules-module-submodule-postId-request"
    }, {
      path: "steps",
      component: _d18858ac,
      name: "modules-module-submodule-postId-steps"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
