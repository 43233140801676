import { render, staticRenderFns } from "./CustomInspectores.vue?vue&type=template&id=fd8f5d84"
import script from "./CustomInspectores.vue?vue&type=script&lang=js"
export * from "./CustomInspectores.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MapAddress: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/Map/Address.vue').default})
