import { render, staticRenderFns } from "./AvatarMenuCompanies.vue?vue&type=template&id=090f7830"
import script from "./AvatarMenuCompanies.vue?vue&type=script&lang=ts"
export * from "./AvatarMenuCompanies.vue?vue&type=script&lang=ts"
import style0 from "./AvatarMenuCompanies.vue?vue&type=style&index=0&id=090f7830&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsIdentidadIcon: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/icons/IdentidadIcon.vue').default})
