import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=75641978&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=75641978&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75641978",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicLogo: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/public/Logo.vue').default,PublicContact: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/public/Contact.vue').default})
