import { render, staticRenderFns } from "./SidebarCiudadano.vue?vue&type=template&id=24cb4bca&scoped=true"
import script from "./SidebarCiudadano.vue?vue&type=script&lang=ts"
export * from "./SidebarCiudadano.vue?vue&type=script&lang=ts"
import style0 from "./SidebarCiudadano.vue?vue&type=style&index=0&id=24cb4bca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24cb4bca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicLogo: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/public/Logo.vue').default,PublicAvatarMenu: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/public/AvatarMenu.vue').default,IconsWalletIcon: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/icons/WalletIcon.vue').default,AuthMiniQrRegister: require('/Users/pammlicona/Documents/Projects/city-manager-platform/components/Auth/MiniQrRegister.vue').default})
